import request from '@/http.js'

/**
 * @description 审核管理 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
export function auditListApi (param) {
    return request.post(
        '/admin.user/getList',
        param
    )
}

/**
 * @description 审核管理 -- 审核
 * @param {Object} param data {Object} 传值参数
 */
 export function auditSaveApi (param) {
    return request.post(
        '/admin.userWithdraw/audit',
        param
    )
}

/**
 * @description 会员卡审核管理 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function auditMemberListApi (param) {
    return request.post(
        '/admin.user/getList',
        param
    )
}

/**
 * @description 会员卡审核管理 -- 审核
 * @param {Object} param data {Object} 传值参数
 */
 export function auditMemberSaveApi (param) {
    return request.post(
        '/admin.user/setExamine',
        param
    )
}

/**
 * 修改汽修厂名称
 * @param {*} param 
 * @returns 
 */
 export function auditEditGarageName (param) {
    return request.post(
        '/admin.user/saveGarageName',
        param
    )
}

